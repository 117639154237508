function Profile({ user }) {
  const { email, phone = 'Телефон', name = 'Имя', post = 'Должность' } = user
  return (
    <div className="result-info mb0">
      <div className="ts">
        <div className="text-lead"> Информация:</div>
        <div className="title"> {name} </div>
        <div className="title"> {post}</div>
      </div>

      <div className="rent-time">
        <div className="text-lead">Контакты:</div>
        {phone !== `""` ? <div className="title"> {phone}</div> : ''}
        <div className="title"> {email}</div>
      </div>
    </div>
  )
}

export default Profile
