import logoSvg from '../../app/img/logo.svg'
import './Logo.css'

const Logo = () => {
  return (
    <div className="logo">
      <img src={logoSvg} alt="Аренда Спецтехники г. Домодедово" />
      <div className="logo-text">
        <div>
          Аренда <br />
          Спецтехники
        </div>
        <span>г. Домодедово</span>
      </div>
    </div>
  )
}

export default Logo
