function Result({
  category,
  transport,
  time,
  date,
  setButtonClick,
  addRent,
  buttonClick,
  offline,
  address,
  setAddress,
  comment,
  setComment,
}) {
  return (
    <div className="result animate__animated animate__fadeIn animate__fast">
      <div className="result-info">
        <div>
          <div className="text-lead">Категория:</div>
          <div className="title">{category}</div>
        </div>
        <div>
          <div className="text-lead">Транспорт:</div>
          <div className="title">{transport}</div>
        </div>

        <div>
          <div className="text-lead">Время бронирования:</div>
          <div className="title">
            <div>{date}</div>
            <div>{time}</div>
          </div>
        </div>
      </div>

      <div className="result-comment">
        <div className="text-lead">Адрес:</div>
        <div className="w100">
          <div>
            <input
              className={!!address ? 'input-result active' : 'input-result'}
              value={address}
              placeholder="Введите адрес доставки т.с"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="text-lead">Комментарий:</div>
        <div className="w100">
          <div>
            <textarea
              rows="3"
              className="textarea-result"
              placeholder="Дополнительная информация для оператора"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </div>
      </div>

      {!offline ? (
        <button
          disabled={buttonClick || !address}
          onClick={() => {
            addRent()
            setButtonClick(true)
          }}
        >
          Забронировать
        </button>
      ) : (
        <div className="offline-info">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>Проверьте Ваш доступ в интернет!</div>
        </div>
      )}
    </div>
  )
}

export default Result
