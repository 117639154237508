import Login from '../components/Login/Login.jsx'
import './LoginPage.css'
import Logo from '../components/Logo/Logo.jsx'

const LoginPage = () => {
  return (
    <div className="login-page">
      <div className="login-page-wrapper  animate__animated animate__fadeIn animate__faster">
        <Logo />
        <Login />
      </div>
    </div>
  )
}

export default LoginPage
