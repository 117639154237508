import { Col, Container, Row, Tab, Nav } from 'react-bootstrap'
import Stage from '../components/Stage/Stage'
import Profile from '../components/Profile/Profile.jsx'
import { useObject } from 'react-firebase-hooks/database'
import { ref, remove } from 'firebase/database'
import { useContext, useEffect, useState } from 'react'
import Context from '../context/Context'
import Calendar from '../components/Calendar/Calendar'
import formatDate from '../func/formatDate'
import bookingTime from '../func/bookingTime'
import Rent from '../components/Rent/Rent'
import Info from '../components/Info/Info'

function ProfilePage({ user }) {
  const { database } = useContext(Context)
  const { auth } = useContext(Context)
  const [dateRent, setDateRent] = useState(new Date())
  const [rentProfile, setRentProfile] = useState([])

  // Данные из таблицы rent для профиля (мое бронирование)
  const [dbRentProfile, dbRentProfileLoading] = useObject(ref(database, 'rent/' + formatDate(dateRent)))

  // Добавляем забронированный транспорт из бд в стейт dbRentProfile
  useEffect(() => {
    if (dbRentProfile) {
      if (dbRentProfile.val() !== null) {
        const obj = []

        Object.entries(dbRentProfile.val()).forEach(([key, value]) => [obj.push({ ...value, key })])

        const filterUid = obj.filter((item) => item.uid === auth.currentUser.uid)

        setRentProfile(filterUid)
      } else {
        setRentProfile([])
      }
    }
  }, [dbRentProfile])

  // Удаление транспорта из бд
  const deleteRent = (key, date) => {
    remove(ref(database, `rent/${date}/${key}`))
  }

  return (
    <Container className="cont profile-page">
      <Col>
        <Stage text="Мой профиль" info="Информация о профиле, история бронирования"></Stage>
      </Col>

      <Tab.Container id="left-tabs-example" defaultActiveKey="first" transition={false}>
        <Row className="animate__animated animate__fadeIn animate__fast">
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">
                  <i className="fi fi-rr-user-pen"></i>
                  <span>Профиль</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  <i className="fi fi-rr-cars"></i>
                  <span>Бронирование</span>
                </Nav.Link>
              </Nav.Item>

              <div className="exit nav-link" onClick={() => auth.signOut()}>
                <i className="fi fi-rr-exit"></i>
                <div>Выход</div>
              </div>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Col sm={12} className=" animate__animated animate__fadeIn animate__fast">
                  <Profile user={user} />
                </Col>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Row className="animate__animated animate__fadeIn animate__fast">
                  <Col sm={12}>
                    <Calendar date={dateRent} setDate={setDateRent} maxDate={false} />
                  </Col>

                  {!rentProfile.length && (
                    <Col sm={12}>
                      <Info loading={dbRentProfileLoading} text="На эту дату у Вас нет забронированного транспорта." />
                    </Col>
                  )}
                  {rentProfile.map((item, id) => (
                    <Col key={id} sm={12}>
                      <Rent
                        today={Date.now()}
                        dateRent={Date.parse(dateRent)}
                        car={item}
                        date={item.date}
                        address={item.address}
                        comment={item.comment}
                        time={bookingTime(item.time)}
                        keyDb={item.key}
                        deleteRent={deleteRent}
                      />
                    </Col>
                  ))}
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  )
}

export default ProfilePage
