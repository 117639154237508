import './Type.css'

const Type = ({ title, icon, active, id, numberTransport, data }) => {
  return (
    <button
      disabled={numberTransport(id) === 0}
      onClick={() => {
        active(id)
      }}
      className={data.stageOne === id ? 'type active' : 'type'}
    >
      <div className="frame">
        <div className="title">{title}</div>
        <div className="text-lead">{numberTransport(id)} видов транспорта</div>
      </div>
      <div className="type-icon">
        {icon !== '' ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
          >
            <g clipPath="url(#clip0_47723_5036)">
              <path d={icon} fill="#0E6C94" />
            </g>
            <defs>
              <clipPath id="clip0_47723_5036">
                <rect width="58" height="58" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <i className="fi fi-rr-steering-wheel"></i>
        )}
      </div>
    </button>
  )
}

export default Type
