import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import toast, { Toaster } from 'react-hot-toast'

import Stage from '../components/Stage/Stage.jsx'
import Car from '../components/Cars/Cars.jsx'

import Type from '../components/Type/Type.jsx'
import { useEffect, useState, useContext } from 'react'

import 'flatpickr/dist/flatpickr.css'
import Result from '../components/Result/Result.jsx'

import Calendar from '../components/Calendar/Calendar.jsx'
import formatDate from '../func/formatDate.js'
import bookingTime from '../func/bookingTime.js'
import Mount from '../components/Mount/Mount.jsx'
import Times from '../components/Times/Times.jsx'
import Context from '../context/Context.js'

import { useObject } from 'react-firebase-hooks/database'
import { onValue, push, ref, remove, set } from 'firebase/database'
import MyLoader from '../components/MyLoader/MyLoader.jsx'

import AdminPage from './AdminPage.jsx'
import ProfilePage from './ProfilePage.jsx'
import AboutPage from './AboutPage.jsx'
import sendEmail from '../func/sendEmail.js'

function MainPage() {
  const { auth } = useContext(Context)
  const { database } = useContext(Context)
  const [date, setDate] = useState(new Date())

  const [address, setAddress] = useState('')
  const [comment, setComment] = useState('')

  const [buttonClick, setButtonClick] = useState(false)
  const [user, setUser] = useState([])
  const [rentTime, setRentTime] = useState([])
  const [offline, setOffline] = useState(false)
  const [dbCat, catLoading, catError] = useObject(ref(database, 'category'))
  const [net, netLoading] = useObject(ref(database, '.info/connected'))
  const [dbCars] = useObject(ref(database, 'cars'))
  const [dbUser] = useObject(ref(database, 'users/' + auth.currentUser.uid))

  // Данные из таблицы rent для 3-го этапа (выбор времени)
  const [dbRent, dbRentLoading] = useObject(ref(database, 'rent/' + formatDate(date)))

  const times = [
    {
      time: '00:00 - 01:00',
      occupied: false,
      active: false,
    },
    {
      time: '01:00 - 02:00',
      occupied: false,
      active: false,
    },
    {
      time: '02:00 - 03:00',
      occupied: false,
      active: false,
    },
    {
      time: '03:00 - 04:00',
      occupied: false,
      active: false,
    },
    {
      time: '04:00 - 05:00',
      occupied: false,
      active: false,
    },
    {
      time: '05:00 - 06:00',
      occupied: false,
      active: false,
    },
    {
      time: '06:00 - 07:00',
      occupied: false,
      active: false,
    },
    {
      time: '07:00 - 08:00',
      occupied: false,
      active: false,
    },
    {
      time: '08:00 - 09:00',
      occupied: false,
      active: false,
    },
    {
      time: '09:00 - 10:00',
      occupied: false,
      active: false,
    },
    {
      time: '10:00 - 11:00',
      occupied: false,
      active: false,
    },
    {
      time: '11:00 - 12:00',
      occupied: false,
      active: false,
    },
    {
      time: '12:00 - 13:00',
      occupied: false,
      active: false,
    },
    {
      time: '13:00 - 14:00',
      occupied: false,
      active: false,
    },
    {
      time: '14:00 - 15:00',
      occupied: false,
      active: false,
    },
    {
      time: '15:00 - 16:00',
      occupied: false,
      active: false,
    },
    {
      time: '16:00 - 17:00',
      occupied: false,
      active: false,
    },
    {
      time: '17:00 - 18:00',
      occupied: false,
      active: false,
    },
    {
      time: '18:00 - 19:00',
      occupied: false,
      active: false,
    },
    {
      time: '19:00 - 20:00',
      occupied: false,
      active: false,
    },
    {
      time: '20:00 - 21:00',
      occupied: false,
      active: false,
    },
    {
      time: '21:00 - 22:00',
      occupied: false,
      active: false,
    },
    {
      time: '22:00 - 23:00',
      occupied: false,
      active: false,
    },
    {
      time: '23:00 - 00:00',
      occupied: false,
      active: false,
    },
  ]

  const timesTwo = [
    {
      time: '00:00 - 00:30',
      occupied: false,
      active: false,
    },
    {
      time: '00:30 - 01:00',
      occupied: false,
      active: false,
    },
    {
      time: '01:00 - 01:30',
      occupied: false,
      active: false,
    },
    {
      time: '01:30 - 02:00',
      occupied: false,
      active: false,
    },
    {
      time: '02:00 - 02:30',
      occupied: false,
      active: false,
    },
    {
      time: '02:30 - 03:00',
      occupied: false,
      active: false,
    },
    {
      time: '03:00 - 03:30',
      occupied: false,
      active: false,
    },
    {
      time: '03:30 - 04:00',
      occupied: false,
      active: false,
    },
    {
      time: '04:00 - 04:30',
      occupied: false,
      active: false,
    },
    {
      time: '04:30 - 05:00',
      occupied: false,
      active: false,
    },
    {
      time: '05:00 - 05:30',
      occupied: false,
      active: false,
    },
    {
      time: '05:30 - 06:00',
      occupied: false,
      active: false,
    },
    {
      time: '06:00 - 06:30',
      occupied: false,
      active: false,
    },
    {
      time: '06:30 - 07:00',
      occupied: false,
      active: false,
    },
    {
      time: '07:00 - 07:30',
      occupied: false,
      active: false,
    },
    {
      time: '07:30 - 08:00',
      occupied: false,
      active: false,
    },
    {
      time: '08:00 - 08:30',
      occupied: false,
      active: false,
    },
    {
      time: '08:30 - 09:00',
      occupied: false,
      active: false,
    },
    {
      time: '09:00 - 09:30',
      occupied: false,
      active: false,
    },
    {
      time: '09:30 - 10:00',
      occupied: false,
      active: false,
    },
    {
      time: '10:00 - 10:30',
      occupied: false,
      active: false,
    },
    {
      time: '10:30 - 11:00',
      occupied: false,
      active: false,
    },
    {
      time: '11:00 - 11:30',
      occupied: false,
      active: false,
    },
    {
      time: '11:30 - 12:00',
      occupied: false,
      active: false,
    },
    {
      time: '12:00 - 12:30',
      occupied: false,
      active: false,
    },
    {
      time: '12:30 - 13:00',
      occupied: false,
      active: false,
    },
    {
      time: '13:00 - 13:30',
      occupied: false,
      active: false,
    },
    {
      time: '13:30 - 14:00',
      occupied: false,
      active: false,
    },
    {
      time: '14:00 - 14:30',
      occupied: false,
      active: false,
    },
    {
      time: '14:30 - 15:00',
      occupied: false,
      active: false,
    },
    {
      time: '15:00 - 15:30',
      occupied: false,
      active: false,
    },

    {
      time: '15:30 - 16:00',
      occupied: false,
      active: false,
    },
    {
      time: '16:00 - 16:30',
      occupied: false,
      active: false,
    },
    {
      time: '16:30 - 17:00',
      occupied: false,
      active: false,
    },

    {
      time: '17:00 - 17:30',
      occupied: false,
      active: false,
    },
    {
      time: '17:30 - 18:00',
      occupied: false,
      active: false,
    },

    {
      time: '18:00 - 18:30',
      occupied: false,
      active: false,
    },
    {
      time: '18:30 - 19:00',
      occupied: false,
      active: false,
    },
    {
      time: '19:00 - 19:30',
      occupied: false,
      active: false,
    },

    {
      time: '19:30 - 20:00',
      occupied: false,
      active: false,
    },
    {
      time: '20:00 - 20:30',
      occupied: false,
      active: false,
    },

    {
      time: '20:30 - 21:00',
      occupied: false,
      active: false,
    },

    {
      time: '21:00 - 21:30',
      occupied: false,
      active: false,
    },
    {
      time: '21:30 - 22:00',
      occupied: false,
      active: false,
    },
    {
      time: '22:00 - 22:30',
      occupied: false,
      active: false,
    },

    {
      time: '22:30 - 23:00',
      occupied: false,
      active: false,
    },
    {
      time: '23:00 - 23:30',
      occupied: false,
      active: false,
    },

    {
      time: '23:30 - 00:00',
      occupied: false,
      active: false,
    },
  ]

  // Основной стейт
  const [data, setData] = useState({
    stageOne: null,
    stageTwo: null,
    stageThree: null,
    date: null,
    times: [...times],
    car: [],
    mount: [],
    selectedСategory: {},
    selectedCar: {},
  })

  // Аренда ТС
  const [rent, setRent] = useState([])

  // Текущие время
  const now = new Date()

  // Проверяем интернет
  useEffect(() => {
    if (!netLoading) {
      setOffline(!net.val())
    }
  }, [net])

  // Добавляем забронированный транспорт из бд в стейт RentTime
  useEffect(() => {
    if (dbRent) {
      if (dbRent.val() !== null) {
        const obj = []

        Object.entries(dbRent.val()).forEach(([key, value]) => [obj.push(value)])
        setData({
          ...data,
          times: data.stageOne === 2 || data.stageOne === 3 ? timesTwo : times,
          stageThree: null,
        })
        setRentTime(obj)
      } else {
        setData({
          ...data,
          times: data.stageOne === 2 || data.stageOne === 3 ? timesTwo : times,
          stageThree: null,
        })
        setRentTime([])
      }
    }
  }, [dbRent])

  // Записать в БД
  useEffect(() => {
    if (buttonClick) {
      toast.promise(
        set(push(ref(database, 'rent/' + formatDate(date))), {
          ...rent,
        })
          .then(() => {
            setButtonClick(false)

            const bodyEmail = {
              subj: 'Бронирование т/с',
              email: user.email,
              name: user.name,
              post: user.post,
              car: rent.name,
              stateNum: rent.stateNumber,
              date: rent.date,
              time: rent.time,
              address: rent.address,
              comment: rent.comment,
            }
            sendEmail(bodyEmail)
          })
          .catch((error) => {
            setButtonClick(false)
          }),
        {
          loading: 'Отправка данных на сервер...',
          success: <b>Транспорт забронирован.</b>,
          error: <b>Ошибка! Попробуйте еще раз.</b>,
        }
      )
    }
  }, [buttonClick])

  // Получаем данные авторизации с сервера и добавляем их setUser
  useEffect(() => {
    const userAuth = auth.currentUser

    if (userAuth !== null && dbUser) {
      const uid = userAuth.uid
      const email = userAuth.email

      setUser({ email, uid, ...dbUser.val() })
    }
  }, [dbUser, auth.currentUser])

  // Отслеживаем изменение на сервере в таблице cars
  useEffect(() => {
    if (data.stageOne) {
      activeStageOne(data.stageOne)
    }
  }, [dbCars])

  // Проверяем изменение в выбранном транспорте и запускаем функцию проверка времени
  useEffect(() => {
    if (dbRent && data.selectedCar.hasOwnProperty('id')) {
      checkRentTime()
    }
  }, [data.selectedCar, date, rentTime])

  // Выделяем вид транспорта
  const activeStageOne = (id) => {
    const checkCar = dbCars.val().filter((item) => item.idCategory === id)
    const selectedСategory = dbCat.val().filter((item) => item.id === id)

    setData({
      ...data,
      stageOne: id,
      car: checkCar,
      stageTwo: null,
      stageThree: null,
      times: data.stageOne === 2 || data.stageOne === 3 ? timesTwo : times,
      selectedСategory: { ...selectedСategory[0] },
    })
  }

  // Выделяем вариант транспорта
  const activeStageTwo = (id) => {
    const selectedCar = dbCars.val().filter((item) => item.id === id)

    setData({
      ...data,
      stageTwo: id,
      stageThree: null,
      selectedCar: { ...selectedCar[0] },
      times: data.stageOne === 2 || data.stageOne === 3 ? timesTwo : times,
    })
  }

  // Дата в отдельном блоке (которая правее от календаря)
  const dateArr = (date) => {
    const dateMount = []

    for (let i = 0; i < 7; i++) {
      const curDate = new Date(date[0])
      dateMount.push(new Date(curDate.setDate(curDate.getDate() + i)))
    }

    setData({ ...data, mount: dateMount })
  }

  // Кол-во транспорта по категориям
  const numberTransport = (id) => {
    if (dbCars) {
      const number = dbCars.val().filter((car) => car.idCategory === id)
      return number.length
    }
  }

  // Кнопка забронировать
  const addRent = () => {
    const timeFilter = data.times.filter((item) => {
      if (item.active) {
        return item.time
      }
    })

    const time = []

    for (let item of timeFilter) {
      time.push(item.time)
    }

    setRent({
      ...data.selectedCar,
      time,
      date: formatDate(date),
      uid: auth.currentUser.uid,
      address,
      comment,
    })

    setData({
      times: times,
      mount: [],
      selectedCar: [],
      stageThree: null,
    })

    setAddress('')
    setComment('')
  }

  // Проверяем забронированное время у транспорта
  const checkRentTime = () => {
    for (let item of rentTime) {
      if (data.selectedCar.id === item.id && item.date === formatDate(date)) {
        const timeOcup = data.times.map((time, i) => {
          for (let ocup of item.time) {
            if (time.time === ocup) {
              time.occupied = true
            }
          }

          return time
        })

        setData({ ...data, times: timeOcup })
      }
    }
  }

  return (
    <div className="App">
      <section>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: 'message container',
            duration: 3000,
            style: {
              background: '#0e6c94',
              color: '#fff',
            },

            // Default options for specific types
            success: {
              duration: 3000,
            },

            loading: {
              duration: 3000,
              style: {
                background: '#fff',
                color: '#1a3353',
              },
            },

            error: {
              duration: 4000,
              style: {
                background: '#fff',
                color: '#ff1f1f',
              },
            },
          }}
        />

        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="container tabmain" transition={false}>
          <Tab tabClassName="home-page" eventKey="home" title="">
            <Container className="cont rent home-page">
              {/* Этап 1 */}
              <div className="animate__animated animate__fadeIn animate__fast">
                <Col>
                  <Stage stage="1" text="Вид транспорта" info="Выберите вид транспортного средства"></Stage>
                </Col>
                <Row>
                  {catError && <strong>Ошибка: {catError}</strong>}
                  {catLoading &&
                    [...Array(5)].map((item, id) => (
                      <Col xs={6} md={6} lg={3} key={id}>
                        <MyLoader viewBox="0 0 300 160" width="100%" height="100" />
                      </Col>
                    ))}
                  {dbCat &&
                    dbCat.val().map((item) => (
                      <Col xs={6} md={6} lg={3} key={item.id}>
                        <Type id={item.id} active={activeStageOne} icon={item.icon} title={item.name} numberTransport={numberTransport} data={data} />
                      </Col>
                    ))}
                </Row>
              </div>

              {/* Этап 2 */}
              {data.stageOne && (
                <div className="animate__animated animate__fadeIn animate__fast">
                  <Col>
                    <Stage stage="2" text="Вариант транспорта" info="Выберите транспортного средства"></Stage>
                  </Col>

                  <Row>
                    {data.car.map((item) => (
                      <Col xs={12} md={6} lg={4} key={item.id}>
                        <Car id={item.id} name={item.name} equipment={item.equipment} number={item.stateNumber} data={data} active={activeStageTwo} />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}

              {/* Этап 3 */}
              {data.stageTwo && (
                <div className="animate__animated animate__fadeIn animate__fast">
                  <Col>
                    <Stage stage="3" text="Дата бронирования" info="Выберите дату бронирования транспорта"></Stage>
                  </Col>
                  <div className="title-date mt-0">
                    <i className="fi fi-rr-calendar-day"></i> Дата
                  </div>
                  <Row>
                    <Col xs={12} md={6} lg={3}>
                      <Calendar date={date} setDate={setDate} dateArr={dateArr} maxDate={6} minDate={true} />
                    </Col>

                    {data.mount.map((item, id) => (
                      <Col key={id} xs={3} md={1} lg={1}>
                        <Mount item={item} setData={setData} setDate={setDate} data={data} times={times} date={date} />
                      </Col>
                    ))}
                  </Row>
                  <div className="title-date">
                    <i className="fi fi-rr-calendar-clock"></i> Время
                  </div>
                  <Row>
                    {!dbRentLoading
                      ? data.times.map((item, id) => (
                          <Col key={id} xs={6} md={2} lg={2}>
                            <Times data={data} setData={setData} item={item} now={now} date={date} />
                          </Col>
                        ))
                      : [...Array(data.stageOne === 2 || data.stageOne === 3 ? timesTwo.length : times.length)].map((item, id) => (
                          <Col xs={6} md={2} lg={2} key={id}>
                            <MyLoader viewBox="0 0 56 360" width="100%" height="56" />
                          </Col>
                        ))}
                  </Row>
                </div>
              )}

              {/* Этап 4 */}
              {data.stageThree && (
                <div className="animate__animated animate__fadeIn animate__fast">
                  <Col>
                    <Stage stage="4" text="Подтверждение" info="Подтвердите дату бронирования"></Stage>
                  </Col>

                  <Row>
                    <Col>
                      <Result
                        category={data.selectedСategory.name}
                        transport={data.selectedCar.name}
                        date={formatDate(date)}
                        time={bookingTime(data.times)}
                        setButtonClick={setButtonClick}
                        addRent={addRent}
                        buttonClick={buttonClick}
                        offline={offline}
                        address={address}
                        setAddress={setAddress}
                        comment={comment}
                        setComment={setComment}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </Container>
          </Tab>

          {/* Мой профиль */}
          <Tab tabClassName="profile-page" eventKey="profile" title="">
            <ProfilePage user={user} />
          </Tab>

          {/* Администрирование */}
          {user.role === 'admin' ? (
            <Tab tabClassName="admin-page" eventKey="admin" title="">
              <AdminPage dbCat={dbCat} dbCars={dbCars} />
            </Tab>
          ) : (
            ''
          )}

          {/* Мой профиль */}
          <Tab tabClassName="about-page" eventKey="about" title="">
            <AboutPage />
          </Tab>
        </Tabs>
      </section>
    </div>
  )
}

export default MainPage
