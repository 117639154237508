import { Container, Col } from 'react-bootstrap'
import Stage from '../components/Stage/Stage'
import { useObject } from 'react-firebase-hooks/database'
import { push, ref, remove, set } from 'firebase/database'
import { useContext, useEffect, useState } from 'react'
import Context from '../context/Context'
import Calendar from '../components/Calendar/Calendar'
import formatDate from '../func/formatDate'
import Form from 'react-bootstrap/Form'
import Info from '../components/Info/Info'
import bookingTime from '../func/bookingTime'
import Remove from '../components/Remove/Remove'

function AdminPage({ dbCat, dbCars }) {
  const { database } = useContext(Context)
  const [rentAdmin, setRentAdmin] = useState([])
  const [cars, setCars] = useState()
  const [time, setTime] = useState([])
  const [userAll, setUserAll] = useState({})
  const [selectCar, setSelectCar] = useState()
  const [selectCat, setSelectCat] = useState()
  const [dateAdmin, setDateAdmin] = useState(new Date())
  const [dbUser, userLoading] = useObject(ref(database, 'users/'))

  const times = [
    {
      time: '00:00 - 01:00',
      occupied: false,
      active: false,
    },
    {
      time: '01:00 - 02:00',
      occupied: false,
      active: false,
    },
    {
      time: '02:00 - 03:00',
      occupied: false,
      active: false,
    },
    {
      time: '03:00 - 04:00',
      occupied: false,
      active: false,
    },
    {
      time: '04:00 - 05:00',
      occupied: false,
      active: false,
    },
    {
      time: '05:00 - 06:00',
      occupied: false,
      active: false,
    },
    {
      time: '06:00 - 07:00',
      occupied: false,
      active: false,
    },
    {
      time: '07:00 - 08:00',
      occupied: false,
      active: false,
    },
    {
      time: '08:00 - 09:00',
      occupied: false,
      active: false,
    },
    {
      time: '09:00 - 10:00',
      occupied: false,
      active: false,
    },
    {
      time: '10:00 - 11:00',
      occupied: false,
      active: false,
    },
    {
      time: '11:00 - 12:00',
      occupied: false,
      active: false,
    },
    {
      time: '12:00 - 13:00',
      occupied: false,
      active: false,
    },
    {
      time: '13:00 - 14:00',
      occupied: false,
      active: false,
    },
    {
      time: '14:00 - 15:00',
      occupied: false,
      active: false,
    },
    {
      time: '15:00 - 16:00',
      occupied: false,
      active: false,
    },
    {
      time: '16:00 - 17:00',
      occupied: false,
      active: false,
    },
    {
      time: '17:00 - 18:00',
      occupied: false,
      active: false,
    },
    {
      time: '18:00 - 19:00',
      occupied: false,
      active: false,
    },
    {
      time: '19:00 - 20:00',
      occupied: false,
      active: false,
    },
    {
      time: '20:00 - 21:00',
      occupied: false,
      active: false,
    },
    {
      time: '21:00 - 22:00',
      occupied: false,
      active: false,
    },
    {
      time: '22:00 - 23:00',
      occupied: false,
      active: false,
    },
    {
      time: '23:00 - 00:00',
      occupied: false,
      active: false,
    },
  ]

  const [timeAll, setTimeAll] = useState(times)

  // Данные из таблицы rent для администратора
  const [dbRentAdmin, dbRentAdminLoading] = useObject(ref(database, 'rent/' + formatDate(dateAdmin)))

  // Добавляем забронированный транспорт из бд в стейт dbRentAdmin
  useEffect(() => {
    if (dbRentAdmin) {
      if (dbRentAdmin.val() !== null) {
        const obj = []

        Object.entries(dbRentAdmin.val()).forEach(([key, value]) => [obj.push({ ...value, key })])

        if (selectCar || selectCat) {
          const filter = obj.filter((item) => {
            return selectCar ? item.id === selectCar : '' || selectCat ? item.idCategory === selectCat : ''
          })

          setRentAdmin(filter)
        } else {
          setRentAdmin(obj)
        }
      } else {
        setRentAdmin([])
      }
    }
  }, [dbRentAdmin, selectCar, selectCat])

  // Добавляем транспорт из бд в стейт cars
  useEffect(() => {
    if (dbCars) {
      if (dbCars.val() !== null) {
        setCars(dbCars.val())
      } else {
        setCars([])
      }
    }
  }, [dbCars])

  // Добавляем всех пользователей из бд в стейт userAll
  useEffect(() => {
    if (dbUser) {
      if (dbUser.val() !== null) {
        setUserAll(dbUser.val())
      } else {
        setUserAll({})
      }
    }
  }, [dbUser])

  // Фильтр для select Транспорт
  const filterCars = (e) => {
    const selected = e.target.value
    document.querySelector('.cars-filter').selectedIndex = 0
    setSelectCar(NaN)
    if (dbCars) {
      if (dbCars.val() !== null) {
        if (selected !== '*') {
          const carsFilter = dbCars.val().filter((item) => item.idCategory === Number(selected))
          setCars(carsFilter)
        } else {
          setCars(dbCars.val())
        }
      }
    }
  }

  return (
    <Container className="cont admin-page">
      <Col>
        <Stage text="Администрирование" info="Управление всеми забронированными т/с"></Stage>
      </Col>
      <div className="animate__animated animate__fadeIn animate__fast">
        <Col sm={12}>
          <div className="admin-filter">
            <Calendar date={dateAdmin} setDate={setDateAdmin} maxDate={false} />

            <Form.Select
              className="cats-filter"
              aria-label="select"
              onChange={(e) => {
                filterCars(e)
                setSelectCat(Number(e.target.value))
              }}
            >
              <option value="*">Категория</option>

              {dbCat &&
                dbCat.val().map((item, id) => (
                  <option key={id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>

            <Form.Select
              className="cars-filter"
              aria-label="select"
              onChange={(e) => {
                setSelectCar(Number(e.target.value))
              }}
            >
              <option value="*">Транспорт</option>
              {cars &&
                cars.map((item, id) => (
                  <option key={id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </Col>
        {!rentAdmin.length && (
          <Col sm={12}>
            <Info loading={dbRentAdminLoading} text="Данные не найдены" />
          </Col>
        )}
        {rentAdmin &&
          rentAdmin.map((item, id) => (
            <div className="result-info" key={id}>
              <div>
                <div className="text-lead">Имя:</div>
                <div className="title">{userAll[item.uid]?.name}</div>
                <div className="title"> {userAll[item.uid]?.post}</div>
              </div>

              <div>
                <div className="text-lead">Транспорт:</div>
                <div className="title"> {item.name}</div>
                <div className="title"> {item.stateNumber} </div>
              </div>

              <div>
                <div className="text-lead">Дата:</div>
                <div className="title"> {item.date} </div>
                <div className="title">{bookingTime(item.time)}</div>
              </div>

              <div>
                <div className="text-lead">Адрес:</div>
                <div className="title"> {item.address} </div>
              </div>

              {!!item.comment && (
                <div>
                  <div className="text-lead">Комментарий:</div>
                  <div className="title"> {item.comment} </div>
                </div>
              )}

              <div className="result-button">
                <Remove date={item.date} id={item.key} userAll={userAll} />
              </div>
            </div>
          ))}
      </div>
    </Container>
  )
}

export default AdminPage
