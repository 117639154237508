function formatDate(date) {
  // if (typeof date === 'string') {
  //   date = new Date(date.replace(/(\d+)\.(\d+)\.(\d+)/, '$3-$2-$1'))
  // }

  date = new Date(date)

  const number = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const mount = date.getMonth()
  const year = date.getFullYear()

  const mountArr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

  return `${number}-${mountArr[mount]}-${year}`
}

export default formatDate
