import './Stage.css'

const Stage = ({ stage, text, info }) => {
  return (
    <div className="element">
      {!!stage && (
        <div className="overlap">
          <h2 className="h-2">
            <span className="text-wrapper">{stage}</span>
            <span className="span">&nbsp;/ 4</span>
          </h2>
        </div>
      )}

      <div className="overlap-group">
        <div className="text-wrapper-2">{text}</div>
        <div className="text-lead">{info}</div>
      </div>
    </div>
  )
}

export default Stage
