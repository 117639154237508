import React from 'react'
import formatDate from '../../func/formatDate'

function Times({ item, setData, data, now, date }) {
  const timeClick = (item) => {
    let status = true

    const newTime = data.times.map((time) => {
      if (time.time === item.time) {
        time.active = !time.active
      }

      return time
    })

    const find = newTime.find((item) => item.active)

    if (!find) {
      status = false
    }

    setData({
      ...data,
      stageThree: status,
      times: [...newTime],
    })
  }

  return (
    <button
      onClick={() => timeClick(item)}
      className={item.active ? 'animate__animated animate__fadeIn animate__fast time active' : 'animate__animated animate__fadeIn animate__fast time'}
      disabled={formatDate(date) === formatDate(now) && Number(item.time.slice(0, 2)) <= Number(now.getHours()) ? 'disabled' : item.occupied}
    >
      <div className="title">{item.time}</div>
      <div className="day-number"></div>
    </button>
  )
}

export default Times
