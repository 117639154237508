const bookingTime = (times) => {
  let result = ''
  let timeArr = []

  if (times !== undefined) {
    // если приходит объект
    if (typeof times[0] === 'object') {
      const time = times.filter((item) => {
        if (item.active) {
          return item
        }
      })

      for (let item of time) {
        timeArr.push(item.time)
      }
    }

    // если просто массив
    if (typeof times[0] === 'string') {
      timeArr = [...times]
    }
  }

  timeArr.push('')

  for (let i = 0; i < timeArr.length; i++) {
    if (timeArr[i + 1] !== undefined) {
      if (i === 0) {
        result = `c ${timeArr[0].substring(0, 5)}`
      }

      if (timeArr[i].substring(8, 10) !== timeArr[i + 1].substring(0, 2)) {
        result += ` до ${timeArr[i].substring(8, 13)}`
        result += ` / c ${timeArr[i + 1].substring(0, 5)}`
      }
    }
  }

  result = result.slice(0, -5)

  const resultArr = result.split(' / ')

  if (resultArr.length >= 2) {
    return (
      <div>
        {result}
        <br />
        <span className="inform">
          <b> Внимание!</b> {resultArr.length} разных
          {resultArr.length >= 2 && resultArr.length <= 4 ? ' интервала ' : ' интервалов '}
          времени.
        </span>
      </div>
    )
  }

  return result
}

export default bookingTime
