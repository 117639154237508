import Flatpickr from 'react-flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru.js'

function Calendar({ date, setDate, dateArr, maxDate, minDate }) {
  return (
    <Flatpickr
      name="flatpickr"
      className="input-date title"
      options={{
        locale: Russian,
        dateFormat: 'd-m-Y',
        minDate: minDate ? 'today' : false,
        disableMobile: 'true',
        defaultDate: 'today',
        enableTime: false,
        wrap: false,
        maxDate: maxDate !== false ? new Date().fp_incr(maxDate) : false,
      }}
      value={date}
      placeholder="Выберите дату"
      onChange={(date) => {
        setDate(date)
      }}
      onReady={(date) => {
        if (dateArr) {
          dateArr(date)
        }

        setDate(date)
      }}
    />
  )
}

export default Calendar
