import { useState } from 'react'

const Form = ({ title, handleClick, alert, setAlert }) => {
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')

  const alertHandler = () => {
    setAlert({
      ...alert,
      status: false,
    })
  }

  return (
    <div className="login-page-form">
      <div className="login-page-inp">
        <label htmlFor="email">
          <span>*</span> Эл. почта
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="name@teplodm.ru"
        />
      </div>

      <div className="login-page-inp">
        <label htmlFor="password">
          <span>*</span> Пароль
        </label>
        <input
          id="password"
          type="password"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          placeholder="Пароль"
        />
      </div>
      <button onClick={() => handleClick(email, pass)}> {title} </button>

      {alert.status ? (
        <div
          className="login-page-alert animate__animated animate__fadeIn animate__faster"
          onClick={alertHandler}
        >
          {alert.textError}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Form
