import { Col, Container, Accordion } from 'react-bootstrap'
import Stage from '../components/Stage/Stage'

function AboutPage() {
  return (
    <Container className="cont about-page">
      <Col>
        <Stage text="Информация" info="Техническая информация о приложении"></Stage>
      </Col>
      <Col>
        <div className="result-info mb0">
          <div className="ts">
            <div className="text-lead"> О приложении:</div>
            <div className="title"> Система бронирования транспорта </div>
          </div>

          <div className="rent-time">
            <div className="text-lead">Разработчик:</div>

            <div className="title">
              <a href="https://vitarts.ru/">vitarts.ru</a>
            </div>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Версия: 1.1.1</Accordion.Header>
              <Accordion.Body>
                <div className="accordion-date">
                  <b>18.12.2023</b>
                </div>
                <ul>
                  <li>Исправлена возможность бронирования т/с, если не выбрано время.</li>
                  <li>Исправлена дата бронирования т/с.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Версия: 1.1</Accordion.Header>
              <Accordion.Body>
                <div className="accordion-date">
                  <b>27.10.2023</b>
                </div>
                <ul>
                  <li>Система уведомлений на эл. почту при бронировании и отмене т/с;</li>
                  <li>Мелкие исправления.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Версия: 1.0.1</Accordion.Header>
              <Accordion.Body>
                <div className="accordion-date">
                  <b>25.10.2023</b>
                </div>
                <ul>
                  <li>Добавлено поле адрес при бронирование т/с;</li>
                  <li>Добавлено поле комментарий;</li>

                  <li>Разный интервал времени бронирования для определенных категорий;</li>
                  <li>Мелкие исправления.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Версия: 1.0</Accordion.Header>
              <Accordion.Body>
                <div className="accordion-date">
                  <b>24.10.2023</b>
                </div>
                <ul>
                  <li>Запуск основной версии.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Col>
    </Container>
  )
}

export default AboutPage
