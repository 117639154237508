import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Routes, Route } from 'react-router-dom'

import LoginPage from '../pages/LoginPage'
import MainPage from '../pages/MainPage'

import { useAuthState } from 'react-firebase-hooks/auth'
import Clear from '../pages/Clear'
import { useContext } from 'react'
import Context from '../context/Context'

function App() {
  const { auth } = useContext(Context)
  const [user, loading, error] = useAuthState(auth)

  if (loading) {
    return <Clear />
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    )
  }
  if (user) {
    return (
      <MainPage />
      // <Routes>
      //   <Route exact path="/" element= />
      // </Routes>
    )
  }
  return <LoginPage />
}

export default App
