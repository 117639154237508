import './Cars.css'

const Cars = ({ name, equipment, number, id, data, active }) => {
  return (
    <button
      onClick={() => {
        active(id)
      }}
      className={
        data.stageTwo === id
          ? 'cars animate__animated animate__fadeIn animate__fast active'
          : 'cars animate__animated animate__fadeIn animate__fast '
      }
    >
      <div className="title">{name}</div>
      {equipment && <div className="equipment">{equipment}</div>}
      <div className="number text-lead">{number}</div>
    </button>
  )
}

export default Cars
