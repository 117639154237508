import Form from './Form'

import { signInWithEmailAndPassword } from 'firebase/auth'
import { useContext, useState } from 'react'
import Context from '../../context/Context'

const Login = () => {
  const { auth } = useContext(Context)
  const [alert, setAlert] = useState({
    status: false,
    textError: '',
  })

  const handleLogin = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        // console.log(user)
      })
      .catch((error) => {
        if (error.code === 'auth/too-many-requests') {
          setAlert({
            ...alert,
            status: true,
            textError: 'Учетная запись заблокирована, попробуйте через 5 минут',
          })
        }

        if (error.code === 'auth/invalid-email') {
          setAlert({
            ...alert,
            status: true,
            textError: 'Неправильно введен E-mail',
          })
        }

        if (error.code === 'auth/wrong-password') {
          setAlert({
            ...alert,
            status: true,
            textError: 'Неправильно введен пароль',
          })
        }

        if (error.code === 'auth/user-not-found') {
          setAlert({
            ...alert,
            status: true,
            textError: 'Пользователь не найден',
          })
        }

        if (error.code === 'auth/missing-password') {
          setAlert({
            ...alert,
            status: true,
            textError: 'Введите пароль',
          })
        }

        setTimeout(() => {
          setAlert({ ...alert, status: false })
        }, 3000)
      })
  }

  return (
    <Form
      title="Войти"
      handleClick={handleLogin}
      alert={alert}
      setAlert={setAlert}
    />
  )
}

export default Login
