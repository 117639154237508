import React from 'react'

function Mount({ item, setDate, setData, data, times, date }) {
  const weekNumber = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']

  const mountClick = (item) => {
    return Date.parse(item) === Date.parse([date])
  }

  return (
    <button
      onClick={() => {
        setDate([item])
        setData({ ...data, times })
      }}
      className={mountClick(item) ? 'day active' : 'day'}
    >
      <div className="title">{weekNumber[item.getDay()]}</div>

      <div className="text-lead">
        {item.getDate()}.
        {item.getMonth() + 1 < 10
          ? `0${item.getMonth() + 1}`
          : item.getMonth() + 1}
      </div>
    </button>
  )
}

export default Mount
