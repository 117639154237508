import React from 'react'

function Info({ loading, text }) {
  return (
    <div className="info-rent">
      <div className="icon">
        <i className="fi fi-rr-exclamation"></i>
      </div>
      <div className="text">{loading ? 'Данные загружаются...' : text}</div>
    </div>
  )
}

export default Info
