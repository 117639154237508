import Remove from '../Remove/Remove'

function Rent({
  car,
  date,
  time,
  deleteRent,
  keyDb,
  today,
  dateRent,
  address,
  comment,
}) {
  const { name, stateNumber } = car

  return (
    <div>
      <div className="result-info mb0">
        <div className="ts">
          <div className="text-lead"> Транспортное средство:</div>
          <div className="title"> {name} </div>
          <div className="title"> {stateNumber}</div>
        </div>

        <div className="rent-time">
          <div className="text-lead">Время бронирования:</div>
          <div className="title"> {date}</div>
          <div className="title"> {time}</div>
        </div>

        <div className="rent-time">
          <div className="text-lead">Адрес:</div>
          <div className="title"> {address}</div>
        </div>

        {!!comment && (
          <div className="rent-time">
            <div className="text-lead">Комментарий:</div>
            <div className="title"> {comment}</div>
          </div>
        )}

        {today < dateRent ? <Remove id={keyDb} date={date} /> : ''}
      </div>
    </div>
  )
}

export default Rent
