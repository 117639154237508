import ContentLoader from 'react-content-loader'

function MyLoader({ props, width, height }) {
  return (
    <div className="loader">
      <ContentLoader
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
        style={{ height: height }}
      >
        <rect
          x="0"
          y="0"
          rx="10"
          ry="10"
          height={height}
          style={{ width: width }}
        />
      </ContentLoader>
    </div>
  )
}

export default MyLoader
