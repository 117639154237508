import { useContext, useEffect, useState } from 'react'
import { ref, remove } from 'firebase/database'
import Context from '../../context/Context'
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useObject } from 'react-firebase-hooks/database'
import sendEmail from '../../func/sendEmail'

function Remove({ id, date }) {
  const { database } = useContext(Context)
  const { auth } = useContext(Context)
  const [show, setShow] = useState(false)
  const [dbRent, dbRentLoading] = useObject(ref(database, `rent/${date}/${id}`))
  const [rent, setRent] = useState()
  const [uid, setUid] = useState()
  const [dbUser] = useObject(ref(database, 'users/' + uid))
  const [dbAuth] = useObject(ref(database, 'users/' + auth.currentUser.uid))
  const [user, setUser] = useState()
  const [userAuth, setUserAuth] = useState()

  // Получаем пользователя с сервера и добавляем их setUser
  useEffect(() => {
    if (dbUser) {
      setUser({ ...dbUser.val() })
    }
  }, [dbUser])

  // Получаем авторизованного пользователя с сервера и добавляем их setUserAuth
  useEffect(() => {
    if (dbAuth) {
      setUserAuth({ ...dbAuth.val() })
    }
  }, [dbAuth])

  // Получаем данные по т/с с сервера
  useEffect(() => {
    if (dbRent) {
      if (dbRent.val() !== null) {
        setRent(dbRent.val())
        setUid(dbRent.val().uid)
      }
    }
  }, [dbRent])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const removeRent = (id, date) => {
    remove(ref(database, `rent/${date}/${id}`))
      .then(() => {
        toast.success('Бронирование отменено!')

        const bodyEmail = {
          subj: 'Отмена бронирования т/с',
          email: user.email,
          name: user.name,
          post: user.post,
          car: rent.name,
          stateNum: rent.stateNumber,
          date: rent.date,
          time: rent.time,
          address: rent.address,
          comment: rent.comment,
          authName: userAuth.name,
        }
        sendEmail(bodyEmail)

        handleClose()
      })
      .catch((error) => {
        toast.error('Ошибка, попробуйте еще раз!')
      })
  }

  return (
    <>
      <button onClick={handleShow}>Отменить</button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Вы действительно хотите отменить бронирование?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Нет
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              removeRent(id, date)
            }}
          >
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Remove
